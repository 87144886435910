/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL } from '@/constants/config';

export const compareFiltersApi = createApi({
  reducerPath: 'compareFilters',
  baseQuery: fetchBaseQuery({ baseUrl: API_URL }),
  tagTypes: ['Campuses', 'Programs'],
  endpoints: (builder) => ({
    getCampuses: builder.query({
      query: (universityId) =>
        `/campuses?where[university_id][equals]=${universityId}`,
      providesTags: ['Campuses'],
      transformResponse: (response: any) => {
        return response?.docs || [];
      }
    }),
    getPrograms: builder.query({
      query: (campusId) => `/programs?where[campus_id][equals]=${campusId}`,
      providesTags: ['Programs'],
      transformResponse: (response: any) => {
        return response?.docs || [];
      }
    }),
    compareUniversities: builder.mutation({
      query: (programIds) => ({
        url: '/compare-universities',
        method: 'POST',
        body: {
          programIds
        }
      }),
      // Invalidate both cache tags when a comparison is made
      invalidatesTags: ['Campuses', 'Programs']
    })
  })
});

// Export the API utilities
export const { util, endpoints } = compareFiltersApi;

// Export hooks
export const {
  useGetCampusesQuery,
  useGetProgramsQuery,
  useCompareUniversitiesMutation
} = compareFiltersApi;

// Export the API itself as default
export default compareFiltersApi;
