import { combineReducers } from 'redux';
import authSlice from '../slices/authSlice';
import { programsApi } from '../api/programApi';
import { userApi } from '../api/userApi';
import admissionSlice from '../slices/admissionSlice';
import scholarshipSlice from '../slices/scholarshipSlice';
import { universitiesApi } from '../api/universitiesApi';
import { contactApi } from '../api/contactApi';
import { applicationsApi } from '../api/applicationApi';
import { specializationsApi } from '../api/specializationApi';
import { compareFiltersApi } from '../api/compareFiltersApi';
import { scholarshipsApi } from '../api/scholarshipApi';

const rootReducer = combineReducers({
  auth: authSlice,
  admission: admissionSlice,
  scholarship: scholarshipSlice,
  [programsApi.reducerPath]: programsApi.reducer,
  [specializationsApi.reducerPath]: specializationsApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [applicationsApi.reducerPath]: applicationsApi.reducer,
  [universitiesApi.reducerPath]: universitiesApi.reducer,
  [contactApi.reducerPath]: contactApi.reducer,
  [compareFiltersApi.reducerPath]: compareFiltersApi.reducer,
  [scholarshipsApi.reducerPath]: scholarshipsApi.reducer
});

export default rootReducer;
