import { API_URL } from '@/constants/config';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from 'js-cookie';

interface GetProgramsParams {
  page?: number;
  search?: string;
  university?: string;
  studyLevel?: string;
  courseForm?: string;
  year?: string;
  intake?: string;
  fee?: string;
  major?: string;
  limit?: number;
}

interface FavoriteParams {
  admissionProgramId: string;
  userIds: string[];
}

export const programsApi = createApi({
  reducerPath: 'programsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers) => {
      const token = Cookies.get('token');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      headers.set('Content-Type', 'application/json');
      return headers;
    }
  }),
  tagTypes: ['Programs', 'Favorites'],
  endpoints: (builder) => ({
    getPrograms: builder.query({
      query: (params) => {
        const { page = 1, limit, ...filters } = params;
        const queryParams = new URLSearchParams({ page: page.toString() });

        if (limit) {
          queryParams.append('limit', limit.toString());
        }

        (
          Object.entries(filters) as [keyof GetProgramsParams, string][]
        ).forEach(([key, value]) => {
          if (value) {
            queryParams.append(key === 'search' ? 'programName' : key, value);
          }
        });

        return {
          url: `admission-programs-with-filters?${queryParams.toString()}`,
          method: 'GET'
        };
      },
      providesTags: ['Programs']
    }),
    getFavoritePrograms: builder.query({
      query: ({ userId, page = 1, limit = 10 }) => ({
        url: `admission_programs?where[favouriteBy][equals]=${userId}&page=${page}&limit=${limit}&depth=5`,
        method: 'GET'
      }),
      providesTags: ['Favorites']
    }),
    toggleFavorite: builder.mutation<void, FavoriteParams>({
      query: ({ admissionProgramId, userIds }) => ({
        url: `/admission_programs/${admissionProgramId}`,
        method: 'PUT',
        body: {
          favouriteBy: userIds
        },
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['Programs', 'Favorites']
    })
  })
});

export const {
  useGetProgramsQuery,
  useToggleFavoriteMutation,
  useGetFavoriteProgramsQuery
} = programsApi;
